import { useMemo } from 'react'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { flashMessage, configureFlashMessages } from 'redux-flash-messages'

let store

export const reducer = combineReducers({
  flashMessage,
})

function initStore(preloadedState = {}) {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware())
  )
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initialState) {
  const reduxStore = useMemo(
    () => initializeStore(initialState),
    [initialState]
  )

  configureFlashMessages({
    // The dispatch function for the Redux store.
    dispatch: reduxStore.dispatch,
  })

  return reduxStore
}
