import React from 'react'
import { Spin, Space } from 'antd'

export default function LoadingIndicator() {
  return (
    <div className="spinner">
      <Space size="middle">
        <Spin size="large" />
      </Space>
      <style jsx>
        {`
          .spinner {
            position: absolute;
            height: 100px;
            width: 100px;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
            background-size: 100%;
          }
        `}
      </style>
    </div>
  )
}
