import { createElement } from 'react'
import getConfig from 'next/config'
import Link from 'next/link'
import {
  DollarCircleOutlined,
  PoundCircleOutlined,
  EuroCircleOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import { CommentNested } from 'graphql/generated/types'
import capitalize from 'lodash/capitalize'
import mixpanel from 'mixpanel-browser'
import PropTypes from 'prop-types'

const { publicRuntimeConfig } = getConfig()

export const NextButtonLink = ({ href, children }) => (
  <Link href={href}>{children}</Link>
)

export const NextLink = ({ href, children, as }) => (
  <Link href={href} as={as}>
    {children}
  </Link>
)

NextLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  as: PropTypes.string.isRequired,
}

export const capitalizeText = (text) =>
  text.split(' ').reduce((acc, val) => `${acc} ${capitalize(val)}`, '')

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/

export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div'
  tag = item.href ? 'a' : tag
  let children =
    typeof item.children === 'string' && item.children.match(isImg)
      ? createElement('img', { src: item.children, alt: 'img' })
      : item.children
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = createElement(Button, {
      ...item.children,
    })
  }
  return createElement(tag, { key: i.toString(), ...item }, children)
}

export const CurrencyIcon = (currency, color) => {
  if (currency === 'pound') {
    return <PoundCircleOutlined style={{ fontSize: '24px', color }} />
  }
  if (currency === 'euro') {
    return <EuroCircleOutlined style={{ fontSize: '24px', color }} />
  }
  return <DollarCircleOutlined style={{ fontSize: '24px', color }} />
}

export const roundToTwo = (num) => {
  return num.toFixed(2)
}

export const IswrongInput = (input) => {
  if (input === '' || typeof input === 'undefined') {
    return true
  }
  const reg = /^-?\d*\.?\d*$/
  const parsedInput = parseFloat(input)
  if (reg.test(input) && parsedInput !== 0) {
    return false
  }
  return true
}

export const unslugify = (slug) => {
  const words = slug.split('-')

  for (let i = 0; i < words.length; i += 1) {
    const word = words[i]
    words[i] = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return words.join(' ')
}

const reduceTwoDigits = (numb: number) => {
  if (numb > 99) {
    return reduceTwoDigits(Math.round(numb / 10 + (numb % 10)))
  }
  return numb
}

export const randNumFromString = (str: string) => {
  const num = str
    .split('')
    .reduce((prevVal, currVal) => prevVal + currVal.charCodeAt(0), 0)
  return reduceTwoDigits(num)
}

export const logoUrl =
  'https://blogassets.fintrakk.com/uploads/2020/10/app-fintrakk-website-1.png'

export const pageUri = (asPath) => `${publicRuntimeConfig.SITE_HOST}${asPath}`

export const countryNameToId = (countryName) => {
  let countryId
  switch (countryName) {
    case 'India': {
      countryId = 1
      break
    }
    case 'Canada': {
      countryId = 2
      break
    }
    case 'USA': {
      countryId = 4
      break
    }

    default: {
      countryId = 1
      break
    }
  }
  return countryId
}

export const ProsCons = (pros_cons) => {
  const pros = []
  const cons = []
  for (let i = 0; i < pros_cons.length; i += 1) {
    if (pros_cons[i].value_type === 'pro') {
      pros.push(pros_cons[i])
    } else {
      cons.push(pros_cons[i])
    }
  }
  return { pros, cons }
}

/**
 * Send mix panel event
 * @param event_name
 * @param props
 */
export const mixpanel_event = (event_name, props) => {
  try {
    if (mixpanel) {
      mixpanel.track(event_name, props)
    } else {
      console.log('Mixpanel not initialized')
    }
  } catch (e) {
    console.log(e)
  }
}

export const countComments = (comments: CommentNested[]) => {
  let count = comments.length
  comments.forEach((item) => {
    if (item.children?.length > 0) {
      count += countComments(item.children)
    }
  })

  return count
}

export function optinMonsterScript({ campaign_id }) {
  const head = document.querySelector('head')
  const script = document.createElement('script')

  script.setAttribute('src', 'https://a.omappapi.com/app/js/api.min.js')
  script.async = true
  script.dataset.user = '95746'
  script.dataset.campaign = campaign_id
  head.appendChild(script)

  return script
}
