import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import getConfig from 'next/config'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import 'assets/antMotionStyle.scss'
import mixpanel from 'mixpanel-browser'
import { addError } from 'redux-flash-messages'
import LoadingIndicator from 'shared/LoadingIndicator'
import { mixpanel_event } from 'shared/util'
import { useApollo } from 'util/apolloClient'
import { useStore } from 'util/store'

const { publicRuntimeConfig } = getConfig()

declare global {
  interface Window {
    Globals: any
    om107067_95746: any
  }
}

export default function App({ Component, pageProps }) {
  const apolloClient = useApollo(publicRuntimeConfig.cmsGraphql, pageProps)
  const store = useStore(pageProps.initialReduxState)
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
    if (
      // On Android chrome browser, the localStorage is not available somehow. Avoid Crashing
      localStorage?.authToken &&
      Router.pathname === '/auth/signin'
    ) {
      addError({ text: 'You Are Already LoggedIn' })
      Router.push('/')
    }

    if (process.env.NODE_ENV === 'production') {
      window.Globals = window.Globals || {}
      if (!window.Globals.MP_INITIALIZED) {
        window.Globals.MP_INITIALIZED = true

        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
      }
      mixpanel_event('page.view', {
        url: window.location.href,
      })
    }

    const start = () => {
      setLoading(true)
    }
    const end = () => {
      setLoading(false)
    }
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', end)
    router.events.on('routeChangeError', end)
    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', end)
      router.events.off('routeChangeError', end)
    }
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider theme={{ hashed: false }}>
        <Provider store={store}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9791786401617970"
                crossOrigin="anonymous"
              />
              <Component {...pageProps} />
            </>
          )}
        </Provider>
      </ConfigProvider>
    </ApolloProvider>
  )
}
